import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import Button from "@mui/material/Button";
import { useNotifications } from "../../hooks/useNotifications";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";

export const UserDeleteButton = ({ user, sx }) => {
  const { apiDelete } = useApi();
  const { push } = useHistory();
  const { setNotifications, success, error } = useNotifications();
  const [submitting, setSubmitting] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [input, setInput] = useState("");

  useEffect(() => {
    if (submitting || !confirm || input !== "delete") return;

    setSubmitting(true);
    apiDelete(`/admin/cognito/user/${user.username}`)
      .then(() => {
        setNotifications([success(`User ${user.username} has been deleted.`)]);
        push("/admin/users");
      })
      .catch((err) => setNotifications([error(err.message)]))
      .finally(() => {
        setConfirm(false);
        setInput("");
        setSubmitting(false);
      });
  }, [
    submitting,
    input,
    confirm,
    setSubmitting,
    apiDelete,
    setNotifications,
    success,
    error,
    push,
    setInput,
    setConfirm,
  ]);

  return submitting ? (
    <CircularProgress />
  ) : !confirm ? (
    <Button
      variant="contained"
      onClick={(event) => {
        event.preventDefault();
        setConfirm(true);
      }}
      color="error"
      sx={sx}
    >
      Delete
    </Button>
  ) : (
    <TextField
      id="confirmDelete"
      name="confirmDelete"
      label="Confirm user deletion by typing 'delete' exactly"
      value={input}
      onChange={(event) => setInput(event.currentTarget.value)}
      variant="filled"
      size="small"
    />
  );
};
