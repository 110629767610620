import { useHistory } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { useUser } from "../../hooks/useUser";
import {
  AuthButton,
  AuthForm,
  AuthTextField,
  passwordHelperText,
} from "../../components/auth/AuthForm";
import { AuthPage } from "../../components/auth/AuthPage";
import { useNotifications } from "../../hooks/useNotifications";

const Form = ({ onSubmit, email }) => (
  <AuthForm
    onSubmit={onSubmit}
    links={[
      { path: "/auth/sign-in", label: "Sign In" },
      { path: "/auth/reset-password", label: "Reset Password" },
      { path: "/auth/sign-up", label: "Create Account" },
      { path: "/auth/confirm-account", label: "Confirm Account" },
    ]}
  >
    <AuthTextField
      id="email"
      label="Email"
      type="email"
      autoComplete="email"
      defaultValue={email}
    />
    <AuthTextField id="code" label="Code" type="text" autoComplete="off" />
    <AuthTextField
      id="password"
      label="New Password"
      type="password"
      autoComplete="new-password"
      helperText={passwordHelperText}
    />
    <AuthButton label="Set New Password" />
  </AuthForm>
);

export const AuthResetPasswordConfirmPage = () => {
  const { confirmPassword } = useUser();
  const [progress, setProgress] = useState(false);
  const { setNotifications, error, success } = useNotifications();
  const { push } = useHistory();

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setProgress(true);
      const formData = new FormData(event.currentTarget);
      try {
        await confirmPassword(
          formData.get("email"),
          formData.get("code"),
          formData.get("password")
        );
        setNotifications([success("Password has been successfully reset.")]);
        push("/auth/sign-in");
      } catch (err) {
        setNotifications([error(err.message)]);
      } finally {
        setProgress(false);
      }
    },
    [setProgress, confirmPassword, push, setNotifications, error, success, setProgress]
  );

  return (
    <AuthPage title="Password Reset Confirm" progress={progress} Form={Form} onSubmit={onSubmit} />
  );
};
