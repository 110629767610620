import React from "react";
import SuggestedGuestEdit from "../../components/suggested/SuggestedGuestEdit";
import { useParams } from "react-router-dom";
import { NarrowPage } from "../../components/layout/NarrowPage";

export const SuggestedGuestIdPage = () => {
  const { id } = useParams();

  return (
    <NarrowPage title="Edit Suggested Guest">
      <SuggestedGuestEdit id={id} />
    </NarrowPage>
  );
};
