import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import useApi from "../../hooks/useApi";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Alert } from "@mui/material";
import { useNotifications } from "../../hooks/useNotifications";
import { arrayToOptions, GridSelect, toUpperFirstLetter } from "../form/GridSelect";
import { SaveButton } from "../form/GridButton";
import { GridDivider } from "../form/GridDivider";
import { GridTextField } from "../form/GridTextField";
import { GridTextAreaField } from "../form/GridTextAreaField";
import { useHistory } from "react-router-dom";
import { shows } from "./SuggestedGuests";

const suggestedGuestApprovalStatus = (guest) => {
  if (guest.approval_status && guest.approval_status !== "unknown") {
    return guest.approval_status;
  }

  if (guest.approved === false) {
    return "rejected";
  } else if (
    guest.approved === null &&
    guest.potential_sponsor !== true &&
    guest.active !== false &&
    guest.email !== null &&
    guest.email !== ""
  ) {
    return "suggested";
  } else if (
    guest.approved === true &&
    guest.potential_sponsor !== true &&
    guest.active !== false
  ) {
    return "approved";
  } else if (guest.potential_sponsor === true && guest.active !== false) {
    return "sponsored";
  } else if (guest.approved === true && guest.active === false) {
    return "booked";
  } else {
    return "suggested";
  }
};

const ApprovalStatusButtons = ({ status, handleStatusChange, disabled }) => {
  const GridButton = ({ label, status, icon }) => (
    <Button
      sx={{ minWidth: 150 }}
      variant="contained"
      startIcon={icon}
      disabled={disabled}
      onClick={() => handleStatusChange(status)}
    >
      {label}
    </Button>
  );

  const ApproveButton = () => <GridButton label="Approve" status="approved" icon={<DoneIcon />} />;

  const RejectButton = () => <GridButton label="Reject" status="rejected" icon={<BlockIcon />} />;

  const MarkSponsoredButton = () => (
    <GridButton label="Sponsored" status="sponsored" icon={<CalendarTodayIcon />} />
  );

  const MarkRequestedButton = () => (
    <GridButton label="Requested" status="requested" icon={<CalendarTodayIcon />} />
  );

  const BookButton = () => <GridButton label="Book" status="booked" icon={<DoneIcon />} />;

  const MarkSuggestedButton = () => (
    <GridButton label="Suggested" status="suggested" icon={<CalendarTodayIcon />} />
  );

  switch (status) {
    case "rejected":
      return (
        <>
          <MarkSuggestedButton />
        </>
      );
    case "requested":
      return (
        <>
          <MarkSuggestedButton />
        </>
      );
    case "sponsored":
      return (
        <>
          <BookButton />
          <RejectButton />
          <MarkSuggestedButton />
        </>
      );
    case "booked":
      return (
        <Alert severity="success">
          This Suggested Guest has been booked and should already have an entry in the guest table.
        </Alert>
      );
    case "approved":
      return (
        <>
          <BookButton />
          <RejectButton />
          <MarkSuggestedButton />
        </>
      );
    default:
      return (
        <>
          <ApproveButton />
          <RejectButton />
          <MarkSponsoredButton />
          <MarkRequestedButton />
        </>
      );
  }
};

export const SuggestedGuestForm = ({
  guest,
  changed,
  onChange,
  saveChanges,
  handleStatusChange,
}) => (
  <form>
    <Box component="ul" sx={{ mt: 2, mb: 4 }}>
      <li>Submitter email: {guest.submitted_by_email}</li>
      <li>Submitter name: {guest.submitted_by_name}</li>
      <li>Submitted by a PR firm: {guest.submitted_by_PR_firm === 1 ? "Yes" : "No"}</li>
      <li>
        Is it ok to contact this guest directly:{" "}
        {guest?.open_for_direct_contact === true
          ? "Yes"
          : guest?.open_for_direct_contact === false
            ? "No"
            : "Not Specified"}
      </li>
    </Box>

    <Grid container spacing={2}>
      <SaveButton onClick={saveChanges} disabled={!changed} />
      <GridTextField
        entity={guest}
        onChange={onChange}
        label="First Name"
        id="firstname"
        required
        disabled
      />
      <GridTextField
        entity={guest}
        onChange={onChange}
        label="Last Name"
        id="lastname"
        required
        disabled
      />
      <GridTextField entity={guest} onChange={onChange} label="Job Title" id="job_title" />
      <GridTextField entity={guest} onChange={onChange} label="Company" id="company" />
      <GridSelect
        entity={guest}
        onChange={onChange}
        id="suggested_show"
        label="Suggested Show"
        options={arrayToOptions(shows.filter((show) => show !== "All"))}
      />
      <GridSelect
        entity={guest}
        onChange={onChange}
        id="suggested_segment_type"
        label="Suggested Segment Type"
        options={arrayToOptions(["interview", "technical"], toUpperFirstLetter)}
      />
      <GridSelect
        entity={guest}
        onChange={onChange}
        id="priority"
        label="Priority"
        options={arrayToOptions(
          ["unknown", "low", "medium", "high", "required"],
          toUpperFirstLetter
        )}
      />
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <ApprovalStatusButtons
            status={suggestedGuestApprovalStatus(guest)}
            handleStatusChange={handleStatusChange}
            disabled={changed}
          />
        </Box>
      </Grid>
      <GridDivider sx={{ mb: 2 }} />
      <GridTextField entity={guest} onChange={onChange} label="Email" id="email" required />
      <GridTextField entity={guest} onChange={onChange} label="Phone" id="phone" />
      <GridTextField entity={guest} onChange={onChange} label="Twitter" id="twitter" />
      <GridTextField entity={guest} onChange={onChange} label="Linkedin" id="linkedin" />
      <GridTextField entity={guest} onChange={onChange} label="Github" id="github" />
      <GridTextField entity={guest} onChange={onChange} label="Website" id="website" />
      <GridTextField entity={guest} onChange={onChange} label="Discord" id="discord" />
      <GridDivider sx={{ my: 2 }} />
      <GridTextAreaField entity={guest} onChange={onChange} id="bio" label="Bio" />
      <GridTextAreaField entity={guest} onChange={onChange} id="reason" label="Reason" />
      <GridTextAreaField entity={guest} onChange={onChange} id="suggested_topic" label="Topic" />
      <GridTextAreaField entity={guest} onChange={onChange} id="host_notes" label="Host Notes" />
      <SaveButton onClick={saveChanges} disabled={!changed} />
    </Grid>
  </form>
);

export const SuggestedGuestEdit = ({ id }) => {
  const { apiGet, apiPut, apiPost, createGuestFromSuggested } = useApi();
  const { setNotifications, error, success, info } = useNotifications();
  const { push } = useHistory();

  const [guest, setGuest] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [changedFields, setChangedFields] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (loaded) return;

    apiGet(`/guest/suggested/${id}`)
      .then(({ data }) => setGuest(data))
      .catch((err) => setNotifications([error(err.message)]))
      .finally(() => setLoaded(true));
  }, [id, apiGet, setGuest, setNotifications, error, loaded, setLoaded]);

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      setGuest({ ...guest, [name]: value });
      setChangedFields({ ...changedFields, [name]: value });
    },
    [guest, setGuest, changedFields, setChangedFields]
  );

  const saveChanges = useCallback(
    (event) => {
      event.preventDefault();
      setSubmitting(true);
      apiPut(`/guest/suggested/${guest.id}`, { data: changedFields })
        .then(({ data }) => {
          setNotifications([success("Suggested guest record has been updated.")]);
          setGuest(data);
          setChangedFields({});
        })
        .catch((err) => setNotifications([error(err.message)]))
        .finally(() => setSubmitting(false));
    },
    [
      apiPut,
      guest,
      setGuest,
      changedFields,
      setChangedFields,
      setNotifications,
      success,
      error,
      setSubmitting,
    ]
  );

  const handleStatusChange = useCallback(
    async (status) => {
      setSubmitting(true);
      const notifications = [];
      try {
        // Create a guest if booking.
        if (status === "booked") {
          if (!guest.email) {
            throw new Error("Must have an email to be booked!");
          }
          const { data } = await apiGet(`/guest/email/${guest.email}`);
          if (data.length) {
            setNotifications([
              info(
                `Guest with ${guest.email} email already exists. Redirecting to the merge page.`
              ),
            ]);
            push(`/guest-merge/${data[0].id}/${guest.id}`);
            return;
          }
          await apiPost("/guest", { data: createGuestFromSuggested(guest) });
          notifications.push(success("Guest record has been created."));
        }

        // Update approval_status on the suggested guest.
        const { data } = await apiPut(`/guest/suggested/${guest.id}`, {
          data: { approval_status: status },
        });
        setGuest(data);
        notifications.push(success("Approval status has been updated"));
      } catch (err) {
        notifications.push(error(err.message));
      }
      setNotifications(notifications);
      setSubmitting(false);
    },
    [
      apiPost,
      apiPut,
      createGuestFromSuggested,
      setSubmitting,
      guest,
      setGuest,
      setNotifications,
      success,
      error,
    ]
  );

  return guest && !submitting ? (
    <SuggestedGuestForm
      guest={guest}
      changed={!!Object.keys(changedFields).length}
      onChange={onChange}
      saveChanges={saveChanges}
      handleStatusChange={handleStatusChange}
    />
  ) : (
    <CircularProgress />
  );
};

export default SuggestedGuestEdit;
