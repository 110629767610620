import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { FormHelperText } from "@mui/material";

export const toUpperFirstLetter = (str) => `${str[0].toUpperCase()}${str.slice(1)}`;

const asIs = (str) => str;

export const arrayToOptions = (options, valueToLabel = asIs) =>
  options.map((option) => ({ value: option, label: valueToLabel(option) }));

export const GridSelect = ({
  label,
  id,
  defaultValue,
  value,
  entity,
  onChange,
  options,
  required,
  disabled,
  helperText,
  sm,
}) => {
  const _value = defaultValue ?? value ?? entity[id];
  if (_value && !options.find((option) => option.value === _value)) {
    options.push({ value: _value, label: _value });
  }

  return (
    <Grid item xs={12} sm={sm ?? 6}>
      <FormControl fullWidth>
        <InputLabel shrink id={`${id}-label`} required={required} variant="filled">
          {label}
        </InputLabel>
        <Select
          labelId={`${id}-label`}
          id={`${id}-select`}
          label={label}
          name={id}
          defaultValue={defaultValue}
          value={value ?? (entity ? entity[id] ?? "" : undefined)}
          onChange={onChange}
          required={required}
          disabled={disabled}
          variant="filled"
        >
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {helperText ? <FormHelperText sx={{ mb: 2 }}>{helperText}</FormHelperText> : null}
      </FormControl>
    </Grid>
  );
};
