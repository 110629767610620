import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GuestForm } from "../../components/guest/GuestForm";
import { NarrowPage } from "../../components/layout/NarrowPage";
import useApi from "../../hooks/useApi";
import CircularProgress from "@mui/material/CircularProgress";
import { useUser } from "../../hooks/useUser";
import { AccessDeniedPage } from "../../components/layout/AccessDeniedPage";
import { useNotifications } from "../../hooks/useNotifications";
import { NotFoundPage } from "../../components/layout/NotFoundPage";
import { GuestHeadshotForm } from "../../components/guest/GuestHeadshotForm";

export const GuestIdPage = () => {
  const { id } = useParams();
  const { user } = useUser();

  const { apiGet } = useApi();
  const [guest, setGuest] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setNotifications, error } = useNotifications();

  useEffect(() => {
    if (!user || !loading) return;

    apiGet(`/guest/${id}`)
      .then(({ data }) => {
        setGuest(data);
      })
      .catch((err) => setNotifications([error(err.message)]))
      .finally(() => setLoading(false));
  }, [id, apiGet, setGuest, loading, setLoading, setNotifications, error]);

  if (!user) return <AccessDeniedPage />;
  if (loading)
    return (
      <NarrowPage title="Edit Guest">
        <CircularProgress />
      </NarrowPage>
    );
  if (!guest) return <NotFoundPage />;

  return (
    <NarrowPage title="Edit Guest">
      <GuestHeadshotForm guest={guest} setGuest={setGuest} />
      <GuestForm guest={guest} setGuest={setGuest} />
    </NarrowPage>
  );
};
