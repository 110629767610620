import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React from "react";
import { FormHelperText } from "@mui/material";

export const GridTextField = ({
  label,
  id,
  type,
  entity,
  onChange,
  disabled,
  required,
  defaultValue,
  sm,
  autoComplete,
  helperText,
  error,
}) => (
  <Grid item xs={12} sm={sm ?? 6}>
    <FormControl fullWidth>
      <TextField
        id={id}
        name={id}
        label={label}
        type={type ?? "text"}
        defaultValue={defaultValue}
        value={entity ? entity[id] ?? "" : undefined}
        onChange={onChange}
        autoComplete={autoComplete ?? "off"}
        fullWidth
        variant="filled"
        required={required}
        disabled={disabled}
        error={error}
      />
      {helperText ? <FormHelperText sx={{ mb: 2 }}>{helperText}</FormHelperText> : null}
    </FormControl>
  </Grid>
);
