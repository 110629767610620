import React from "react";
import { Alert } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Paragraph = ({ children }) => (
  <Typography component="p" sx={{ mb: 1 }}>
    {children}
  </Typography>
);

export const NoGuest = () => (
  <>
    <Alert severity="error" sx={{ mb: 3 }}>
      No Matching Guest Entry Found
    </Alert>
    <Paragraph>Welcome to Security Weekly Productions.</Paragraph>
    <Paragraph>
      Unfortunately, we could not find a guest entry associated with the email address you&apos;ve
      used to create your account.
    </Paragraph>
    <Paragraph>
      If you believe this is an error, please contact Renee Alderman at{" "}
      <Link href="mailto:renee.alderman@cyberriskalliance.com">
        renee.alderman@cyberriskalliance.com
      </Link>
      .
    </Paragraph>
    <Paragraph>
      Thank you,
      <br />
      Security Weekly Productions Team
    </Paragraph>
  </>
);

export default NoGuest;
