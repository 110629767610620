import Typography from "@mui/material/Typography";
import React from "react";
import { useNotifications } from "../../hooks/useNotifications";
import Box from "@mui/material/Box";

export const WidePage = ({ title, children, sx }) => {
  const { Notifications } = useNotifications();
  return (
    <Box component="main" sx={{ my: 8, textAlign: "center", px: 5, ...(sx ?? {}) }}>
      {title ? (
        <>
          <Typography component="h1" variant="h4" sx={{ mb: 2 }}>
            {title}
          </Typography>
        </>
      ) : null}
      <Notifications sx={{ my: 2 }} />
      {children}
    </Box>
  );
};
