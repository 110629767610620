import React, { useEffect, useState } from "react";
import { GuestsTable } from "../components/guest/GuestsTable";
import { useUser } from "../hooks/useUser";
import useApi from "../hooks/useApi";
import CircularProgress from "@mui/material/CircularProgress";
import { AccessDeniedPage } from "../components/layout/AccessDeniedPage";
import { useNotifications } from "../hooks/useNotifications";
import { WidePage } from "../components/layout/WidePage";

export const GuestsPage = () => {
  const { user } = useUser();
  const { setNotifications, error } = useNotifications();

  const { apiGet } = useApi();
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user || !loading) return;

    apiGet("/guest")
      .then(({ data }) => {
        setGuests(data);
      })
      .catch((err) => setNotifications([error(err.message)]))
      .finally(() => setLoading(false));
  }, [apiGet, setGuests, loading, setLoading, setNotifications, error]);

  if (!user) return <AccessDeniedPage />;

  return (
    <WidePage title="Guests">
      {loading ? <CircularProgress /> : <GuestsTable guests={guests} setGuests={setGuests} />}
    </WidePage>
  );
};
