import React from "react";

import SuggestedGuests from "../components/suggested/SuggestedGuests.jsx";
import { WidePage } from "../components/layout/WidePage";

export const SuggestedGuestsPage = () => (
  <WidePage title="Suggested Guests">
    <SuggestedGuests />
  </WidePage>
);
