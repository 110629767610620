import React, {useMemo, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import SignOutButton from "../auth/SignOutButton";
import {NavLink} from "react-router-dom";
import useRoutes from "../../hooks/useRoutes";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

const Logo = () => <img src="/logo.svg" height="50" alt="CyberRisk Alliance Logo" />;

const NavButton = ({ path, name, sx }) =>
  (
    <NavLink to={path} style={{textDecoration: "none"}}>
      <Button
        sx={{my: 2, color: "white", display: "block", ...(sx ?? {})}}
        variant="contained"
      >
        {name}
      </Button>
    </NavLink>
  );

export const Navbar = () => {
  const { routes } = useRoutes();

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);

  const handleCloseNavMenu = () => setAnchorElNav(null);

  const navigationRoutes = useMemo(
    () => routes.filter((route) => route.menu === "navigation"),
    [routes]
  );

  const userRoutes = useMemo(() => routes.filter((route) => route.menu === "user"), [routes]);

  return (
    <AppBar position="static" sx={{ backgroundColor: "#0145b2" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: "flex", mr: 1 }}>
            <Logo />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {navigationRoutes.map(({ name, path, access }) => (
                <MenuItem key={`${path}-${access}`} onClick={handleCloseNavMenu}>
                  <Link component={NavLink} to={path} underline="none" color="black">
                    {name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: "center" }}>
            {navigationRoutes.map((route) => (
              <NavButton key={`${route.path}-${route.access}`} {...route} sx={{ mx: 2 }} />
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {userRoutes.map((route) => (
              <NavButton key={`${route.path}-${route.access}`} {...route} />
            ))}
            <SignOutButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
