import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Container from "@mui/material/Container";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import useApi from "../../hooks/useApi";
import CircularProgress from "@mui/material/CircularProgress";
import { useNotifications } from "../../hooks/useNotifications";

const isGuestValidForThisTab = (guest, tab) => {
  // If approval_status field exists and is not "unknown", use it.
  if (guest.approval_status && guest.approval_status !== "unknown") {
    return guest.approval_status === tab;
  }

  // Implement the old filtering logic for entries missing approval_status.
  switch (tab) {
    case "approved":
      return guest.approved === true && guest.potential_sponsor !== true && guest.active !== false;
    case "suggested":
      return (
        guest.approved === null &&
        guest.potential_sponsor !== true &&
        guest.active !== false &&
        guest.email !== null &&
        guest.email !== ""
      );
    case "booked":
      return guest.approved === true && guest.active === false;
    case "sponsored":
      return guest.potential_sponsor === true && guest.active !== false;
    case "rejected":
      return guest.approved === false;
    default:
      return false;
  }
};

export const shows = [
  "All",
  "Application Security Weekly",
  "Business Security Weekly",
  "The CISO Stories Podcast",
  "Enterprise Security Weekly",
  "Paul's Security Weekly",
  "RSAC",
  "Security Weekly News",
];

export const SuggestedGuests = () => {
  const { apiGet, apiPost, apiPut, createGuestFromSuggested } = useApi();
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setNotifications, error } = useNotifications();

  useEffect(() => {
    if (!loading) return;

    apiGet("/guest/suggested")
      .then((response) => setGuests(response.data))
      .catch((err) => setNotifications([error(err.message)]))
      .finally(() => setLoading(false));
  }, [apiGet, setGuests, loading, setLoading, setNotifications, error]);

  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tab = useMemo(() => searchParams.get("tab") || "suggested", [searchParams]);
  const selectedShow = useMemo(() => searchParams.get("show") || "All", [searchParams]);

  const [selectedGuestIds, setSelectedGuestIds] = useState([]);
  const [batchAction, setBatchAction] = useState("");
  const [batching, setBatching] = useState(false);

  const rows = useMemo(
    () =>
      guests
        .filter((guest) => isGuestValidForThisTab(guest, tab))
        .filter((guest) => selectedShow === "All" || guest.suggested_show === selectedShow),
    [guests, tab, selectedShow]
  );

  const submitBatch = useCallback(async () => {
    setBatching(true);
    setBatchAction("");
    await Promise.allSettled(
      selectedGuestIds.map(async (id) => {
        if (batchAction === "booked") {
          const guest = guests.find((guest) => guest.id === id);
          await apiPost("/guest", { data: createGuestFromSuggested(guest) });
        }
        return apiPut(`/guest/suggested/${id}`, { data: { approval_status: batchAction } });
      })
    ).then((results) => {
      setNotifications(
        results
          .filter(({ status }) => status === "rejected")
          .map((result) => {
            const regex = /^Entity with (.*?) already exists$/gm;
            const match = regex.exec(result.reason.message);
            const err = match
              ? `Guest with email ${match[1]} already exists! Click "Book" button inside the suggested guest profile to merge the guest record.`
              : result.reason.message;
            return error(err);
          })
      );

      const updatedGuests = results
        .filter(({ status }) => status === "fulfilled")
        .map((result) => result?.value?.data);

      setGuests(
        guests.map(
          (guest) => updatedGuests.find((updatedGuest) => updatedGuest.id === guest.id) ?? guest
        )
      );
      setBatching(false);
    });
  }, [
    setBatching,
    setBatchAction,
    apiPut,
    apiPost,
    createGuestFromSuggested,
    selectedGuestIds,
    batchAction,
    guests,
    setGuests,
    setNotifications,
    error,
  ]);

  const columns = useMemo(
    () => [
      {
        field: "fullName",
        headerName: "Full Name",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        width: 160,
        valueGetter: ({ row }) => `${row.firstname ?? ""} ${row.lastname ?? ""}`,
        renderCell: ({ row }) => (
          <Link component={RouterLink} to={`/suggested-guest/${row.id}`}>
            {`${row.firstname ?? ""} ${row.lastname ?? ""}`}
          </Link>
        ),
      },
      {
        field: "firstname",
        headerName: "First Name",
        width: 150,
        editable: true,
      },
      { field: "lastname", headerName: "Last Name", width: 150, editable: true },

      { field: "email", headerName: "Email", width: 200 },
      { field: "company", headerName: "Company", width: 200 },
      { field: "job_title", headerName: "Title", width: 150 },
      { field: "suggested_show", headerName: "Suggested Show", width: 200 },
      { field: "priority", headerName: "Priority", width: 120 },
      { field: "submitted_by_email", headerName: "Submitter", width: 200 },
      {
        field: "open_for_direct_contact",
        headerName: "Direct Contact?",
        width: 160,
      },
      {
        field: "onboarding_email_sent",
        headerName: "Onboard Email Sent",
        width: 200,
        editable: false,
        disableClickEventBubbling: true,
        renderCell: ({ row, value }) => (
          <Checkbox
            checked={value}
            onChange={() => {
              apiPut(`/guest/suggested/${row.id}`, { data: { onboarding_email_sent: !value } })
                .then(({ data: updatedGuest }) => {
                  setGuests(
                    guests.map((guest) => (guest.id === updatedGuest.id ? updatedGuest : guest))
                  );
                })
                .catch((err) => setNotifications([error(err.message)]));
            }}
          />
        ),
      },
      {
        field: "created_at",
        headerName: "Created",
        sortable: true,
        width: 200,
        renderCell: ({ row: { created_at } }) => new Date(created_at).toLocaleString(),
      },
    ],
    [apiPut, guests, setGuests, setNotifications, error]
  );

  const batchOptions = useMemo(
    () =>
      [
        { value: "rejected", label: "Mark Rejected" },
        { value: "requested", label: "Mark Requested" },
        { value: "suggested", label: "Mark Suggested" },
        { value: "approved", label: "Mark Approved" },
        { value: "sponsored", label: "Mark Sponsored" },
        { value: "booked", label: "Mark Booked" },
      ].filter(({ value }) => {
        if (tab === "rejected") return ["suggested", "requested"].includes(value);
        if (tab === "requested") return ["suggested", "approved", "sponsored"].includes(value);
        if (tab === "suggested")
          return ["rejected", "requested", "approved", "sponsored"].includes(value);
        if (tab === "approved")
          return ["rejected", "requested", "suggested", "sponsored", "booked"].includes(value);
        if (tab === "sponsored")
          return ["rejected", "requested", "suggested", "approved", "booked"].includes(value);
        if (tab === "booked") return false;
        return value !== tab;
      }),
    [tab]
  );

  if (loading)
    return (
      <Container maxWidth="md" sx={{ marginY: 3 }}>
        <CircularProgress />
      </Container>
    );

  return (
    <>
      <Container maxWidth="md" sx={{ marginY: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="batch-action-label">Batch Action</InputLabel>
              <Select
                labelId="batch-action-label"
                id="batch-action"
                value={batchAction}
                onChange={(event) => setBatchAction(event.target.value)}
              >
                {batchOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {batching ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!selectedGuestIds.length || !batchAction}
                onClick={submitBatch}
              >
                Submit Batch Action
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="show-select-label">Filter By Show</InputLabel>
              <Select
                labelId="show-select-label"
                id="show-select"
                value={selectedShow}
                onChange={(event) => push(`${pathname}?tab=${tab}&show=${event.target.value}`)}
              >
                {shows.map((show) => (
                  <MenuItem key={show} value={show}>
                    {show}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false}>
        <Paper square>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => {
              setBatchAction("");
              push(`${pathname}?tab=${value}&show=${selectedShow}`);
            }}
            aria-label="approval status tabs"
          >
            <Tab label="Rejected" value="rejected" />
            <Tab label="Requested" value="requested" />
            <Tab label="Suggested" value="suggested" />
            <Tab label="Approved" value="approved" />
            <Tab label="Sponsored" value="sponsored" />
            <Tab label="Booked" value="booked" />
          </Tabs>
        </Paper>
        <Box sx={{ height: "100%", width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: 50, page: 0 } },
              sorting: { sortModel: [{ field: "created_at", sort: "asc" }] },
            }}
            pageSizeOptions={[15, 30, 50, 100]}
            checkboxSelection
            onRowSelectionModelChange={(ids) => setSelectedGuestIds(ids)}
            disableRowSelectionOnClick
            slots={{
              toolbar: GridToolbar,
            }}
            density="compact"
          />
        </Box>
      </Container>
    </>
  );
};

export default SuggestedGuests;
