import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {GuestForm} from "../components/guest/GuestForm";
import useApi from "../hooks/useApi";
import CircularProgress from "@mui/material/CircularProgress";
import {useUser} from "../hooks/useUser";
import {AccessDeniedPage} from "../components/layout/AccessDeniedPage";
import {useNotifications} from "../hooks/useNotifications";
import {NotFoundPage} from "../components/layout/NotFoundPage";
import {WidePage} from "../components/layout/WidePage";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {SuggestedGuestMerge} from "../components/suggested/SuggestedGuestMerge";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {GuestHeadshotForm} from "../components/guest/GuestHeadshotForm";

export const GuestMergePage = () => {
  const { id, suggestedGuestId } = useParams();
  const { user } = useUser();

  const { apiGet } = useApi();
  const [guest, setGuest] = useState(null);
  const [suggestedGuest, setSuggestedGuest] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setNotifications, error } = useNotifications();

  useEffect(() => {
    if (!user || !loading) return;

    Promise.allSettled([
      apiGet(`/guest/${id}`),
      apiGet(`/guest/suggested/${suggestedGuestId}`),
    ]).then((results) => {
      const notifications = results
        .filter(({ status }) => status === "rejected")
        .map((result) => error(result.reason.message));
      if (notifications.length) {
        setNotifications(notifications);
        setLoading(false);
        return;
      }
      setGuest(results[0].value.data);
      setSuggestedGuest(results[1].value.data);
      setLoading(false);
    });
  }, [id, apiGet, setGuest, setSuggestedGuest, loading, setLoading, setNotifications, error]);

  if (!user) return <AccessDeniedPage />;
  if (loading)
    return (
      <WidePage title="Guest Merge">
        <CircularProgress />
      </WidePage>
    );
  if (!guest) return <NotFoundPage />;

  return (
    <WidePage title="Guest Merge" sx={{ px: 5 }}>
      <Box sx={{ textAlign: "left", mt: 5 }}>
        <Grid container spacing={5}>
          <Grid xs={12} md={6}>
            <Paper variant="elevation" sx={{ padding: 3 }}>
              <Typography component="h2" variant="h5" sx={{ mb: 2 }}>
                Suggested Guest
              </Typography>
              <Box sx={{ my: 2 }}>
                <Link to={`/suggested-guest/${suggestedGuest.id}`} sx={{ my: 2 }}>
                  <Button variant="contained">Return to Suggested Guest Edit page</Button>
                </Link>
              </Box>
              <SuggestedGuestMerge guest={suggestedGuest} setGuest={setSuggestedGuest} />
            </Paper>
          </Grid>
          <Grid xs={12} md={6}>
            <Paper variant="elevation" sx={{ padding: 3 }}>
              <Typography component="h2" variant="h5" sx={{ mb: 2 }}>
                Guest
              </Typography>
              <Box sx={{ my: 2 }}>
                <Link to={`/guest/${guest.id}`} sx={{ my: 2 }}>
                  <Button variant="contained">Go to Guest Edit page</Button>
                </Link>
              </Box>
              <GuestForm guest={guest} setGuest={setGuest} suggestedGuest={suggestedGuest} />
              <GuestHeadshotForm guest={guest} setGuest={setGuest} sx={{ mb: 0, mt: 2 }} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </WidePage>
  );
};
