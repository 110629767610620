import React from "react";
import Button from "@mui/material/Button";
import { useUser } from "../../hooks/useUser";
import { useHistory } from "react-router-dom";

export const SignOutButton = () => {
  const { user, signOut } = useUser();
  const { push } = useHistory();

  if (!user) return null;

  const onClick = async (event) => {
    event.preventDefault();
    await signOut();
    push("/auth/sign-in");
  };

  return (
    <Button variant="contained" onClick={onClick}>
      Sign Out
    </Button>
  );
};

export default SignOutButton;
