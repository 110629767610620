import React, { useCallback, useState } from "react";
import useApi from "../../hooks/useApi";
import { useNotifications } from "../../hooks/useNotifications";
import Grid from "@mui/material/Grid";
import { GridTextField } from "../form/GridTextField";
import CircularProgress from "@mui/material/CircularProgress";
import { GridTextAreaField } from "../form/GridTextAreaField";
import { GridDivider } from "../form/GridDivider";
import { SaveButton } from "../form/GridButton";
import { Alert } from "@mui/material";

const Form = ({ guest, confirmBooking }) => (
  <form>
    <Grid container spacing={2}>
      <GridTextField label="First Name" id="firstname" entity={guest} disabled />
      <GridTextField label="Last Name" id="lastname" entity={guest} disabled />
      <GridTextField label="Job Title" id="job_title" entity={guest} disabled />
      <GridTextField label="Company" id="company" entity={guest} disabled />
      <GridTextAreaField label="Bio" id="bio" entity={guest} disabled />
      <GridDivider />
      <GridTextField label="Email" id="email" entity={guest} disabled />
      <GridTextField label="Phone" id="phone" entity={guest} disabled />
      <GridTextField label="Website" id="website" entity={guest} disabled />
      <GridTextField label="Twitter" id="twitter" entity={guest} disabled />
      <GridTextField label="Linkedin" id="linkedin" entity={guest} disabled />
      <GridTextField label="Github" id="github" entity={guest} disabled />
      <GridTextField label="Discord" id="discord" entity={guest} disabled />
      {guest.approval_status === "booked" ? (
        <Grid item xs={12}>
          <Alert>
            This suggested guest is booked. You might want to update the guest record on the right
            (fields marked with red) with the info here.
          </Alert>
        </Grid>
      ) : (
        <SaveButton
          onClick={confirmBooking}
          disabled={guest.approval_status === "booked"}
          label="Confirm and Mark as Booked"
        />
      )}

      <Grid item xs={12}>
        <ul>
          <li>Submitter email: {guest.submitted_by_email}</li>
          <li>Submitter name: {guest.submitted_by_name}</li>
          <li>Submitted by a PR firm: {guest.submitted_by_PR_firm === 1 ? "Yes" : "No"}</li>
          <li>
            Is it ok to contact this guest directly:{" "}
            {guest?.open_for_direct_contact === true
              ? "Yes"
              : guest?.open_for_direct_contact === false
                ? "No"
                : "Not Specified"}
          </li>
        </ul>
      </Grid>
      <GridTextField label="Suggested Show" id="suggested_show" entity={guest} disabled />
      <GridTextField
        label="Suggested Segment Type"
        id="suggested_segment_type"
        entity={guest}
        disabled
      />
      <GridTextField label="Priority" id="priority" entity={guest} disabled />
      <GridTextAreaField entity={guest} id="reason" label="Reason" disabled />
      <GridTextAreaField entity={guest} id="suggested_topic" label="Topic" disabled />
      <GridTextAreaField entity={guest} id="host_notes" label="Host Notes" disabled />
    </Grid>
  </form>
);

export const SuggestedGuestMerge = ({ guest, setGuest }) => {
  const { apiPut } = useApi();
  const { setNotifications, success, error } = useNotifications();
  const [submitting, setSubmitting] = useState(false);

  const confirmBooking = useCallback(
    (event) => {
      event.preventDefault();
      setSubmitting(true);
      apiPut(`/guest/suggested/${guest.id}`, { data: { approval_status: "booked" } })
        .then(({ data }) => {
          setNotifications([success("Suggested guest has been booked.")]);
          setGuest(data);
        })
        .catch((err) => setNotifications([error(err.message)]))
        .finally(() => setSubmitting(false));
    },
    [apiPut, guest, setGuest, setNotifications, error, success, setSubmitting]
  );

  return !submitting ? (
    <Form guest={guest} confirmBooking={confirmBooking} />
  ) : (
    <CircularProgress />
  );
};
