import React from "react";
import Container from "@mui/material/Container";
import { AppBar, Typography } from "@mui/material";
import Box from "@mui/material/Box";

export const Footer = ({ version }) => (
  <AppBar component="footer" position="static" sx={{ backgroundColor: "#0145b2" }}>
    <Box sx={{ paddingY: 2, textAlign: "center" }}>
      <Container maxWidth="md">
        <Typography variant="body1" color="inherit" display="block">
          &#169; 2023 Security Weekly Productions <br />
          Version: {version}
        </Typography>
      </Container>
    </Box>
  </AppBar>
);

export default Footer;
