import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React from "react";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";

export const AuthButton = ({ label, onClick, sx, type }) => (
  <Box sx={sx ?? { my: 2 }}>
    <Button color="primary" type={type ?? "submit"} fullWidth variant="contained" onClick={onClick}>
      {label}
    </Button>
  </Box>
);

export const AuthTextField = ({
  id,
  label,
  type,
  autoComplete,
  defaultValue,
  value,
  onChange,
  helperText,
}) => (
  <FormControl fullWidth>
    <TextField
      margin="normal"
      required
      fullWidth
      id={id}
      name={id}
      label={label}
      type={type ?? "text"}
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    />
    {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
  </FormControl>
);

export const AuthForm = ({ onSubmit, children, links }) => (
  <Box component="form" onSubmit={onSubmit} sx={{ mt: 1, width: "100%" }}>
    {children}
    <Box
      sx={{
        marginTop: 3,
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
      }}
    >
      {(links ?? []).map(({ path, label }) => (
        <Link key={path} to={path} variant="body2">
          {label}
        </Link>
      ))}
    </Box>
  </Box>
);

export const passwordHelperText =
  "Password has to be at least 12 characters long. Also, it must contain at least 1 number, 1 uppercase letter, and 1 lowercase letter.";
