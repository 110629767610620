import React from "react";
import {Link as RouterLink} from "react-router-dom";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";

export const GuestsTable = ({ guests }) => {
  const columns = [
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      minWidth: 250,
      renderCell: ({ row: { id, name } }) => (
        <Link component={RouterLink} to={`/guest/${id}`}>
          {name}
        </Link>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      minWidth: 300,
    },
    {
      field: "company",
      headerName: "Company",
      sortable: true,
      minWidth: 250,
    },
    {
      field: "title",
      headerName: "Job Title",
      sortable: true,
      minWidth: 250,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: true,
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Created At",
      sortable: true,
      minWidth: 200,
      renderCell: ({ row: { created_at } }) => new Date(created_at).toLocaleString(),
    },
  ];

  return (
    <Container maxWidth="xl">
      <DataGrid
        columns={columns}
        rows={guests}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 50, page: 0 } },
          sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
        }}
        pageSizeOptions={[15, 30, 50, 100]}
        slots={{
          toolbar: GridToolbar,
        }}
        density="compact"
      />
    </Container>
  );
};
