import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import React from "react";

export const GridButton = ({ children, onClick, disabled, xs, sm }) => (
  <Grid item xs={xs ?? 12} sm={sm}>
    <Button
      fullWidth
      variant="contained"
      onClick={onClick}
      startIcon={<SaveIcon />}
      disabled={disabled}
    >
      {children}
    </Button>
  </Grid>
);

export const SaveButton = ({ onClick, disabled, xs, sm, label }) => (
  <GridButton onClick={onClick} disabled={disabled} xs={xs} sm={sm}>
    {label ?? "Save"}
  </GridButton>
);
