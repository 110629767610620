import React, { useEffect, useState } from "react";
import { useUser } from "../../hooks/useUser";
import useApi from "../../hooks/useApi";
import CircularProgress from "@mui/material/CircularProgress";
import { AccessDeniedPage } from "../../components/layout/AccessDeniedPage";
import { useNotifications } from "../../hooks/useNotifications";
import { WidePage } from "../../components/layout/WidePage";
import { UsersTable } from "../../components/user/UsersTable";

export const AdminUsersPage = () => {
  const { user } = useUser();
  const { setNotifications, error } = useNotifications();

  const { apiGet } = useApi();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user || !loading) return;

    apiGet("/admin/cognito/users")
      .then(({ data }) => {
        setUsers(data);
      })
      .catch((err) => setNotifications([error(err.message)]))
      .finally(() => setLoading(false));
  }, [apiGet, setUsers, loading, setLoading, setNotifications, error]);

  if (!user || !user.isAdmin) return <AccessDeniedPage />;

  return (
    <WidePage title="Users">
      {loading ? <CircularProgress /> : <UsersTable users={users} />}
    </WidePage>
  );
};
