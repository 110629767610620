import React, { useState } from "react";

import useApi from "../../hooks/useApi";
import { useNotifications } from "../../hooks/useNotifications";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Button from "@mui/material/Button";

const Headshot = ({ src }) => (
  <Box>
    {src ? (
      <Box
        component="img"
        src={src}
        width={120}
        height={120}
        alt="Headshot"
        sx={{ objectFit: "cover", borderRadius: "50%" }}
      />
    ) : (
      <AccountCircleIcon sx={{ fontSize: 130 }} />
    )}
  </Box>
);

const Form = ({ uploadHeadshot }) => (
  <Box
    component="form"
    sx={{ display: "flex", flexDirection: "column", justifyContent: "center", ml: 2 }}
  >
    <Button component="label" size="small" variant="contained" color="primary">
      Upload Headshot
      <input
        type="file"
        name="headshot"
        id="headshot"
        hidden
        accept="image/*"
        onChange={(event) => {
          const formData = new FormData();
          formData.append("headshot", event.target.files[0]);
          void uploadHeadshot(formData);
        }}
      />
    </Button>
  </Box>
);

export const GuestHeadshotForm = ({ guest, setGuest, sx }) => {
  const { apiPut } = useApi();
  const { setNotifications, success, error } = useNotifications();
  const [submitting, setSubmitting] = useState(false);

  const uploadHeadshot = async (formData) => {
    setSubmitting(true);
    try {
      const { data } = await apiPut(`/guest/${guest.id}/headshot`, formData);
      setGuest(data);
      setNotifications([success("Headshot has been updated!")]);
    } catch (err) {
      setNotifications([error(err.mesage)]);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", mb: 4, ...(sx ?? {}) }}>
      <Headshot src={guest.headshot} />
      {submitting ? <CircularProgress /> : <Form uploadHeadshot={uploadHeadshot} />}
    </Box>
  );
};
