import React, { useCallback, useState } from "react";
import useApi from "../../hooks/useApi";
import { useNotifications } from "../../hooks/useNotifications";
import Grid from "@mui/material/Grid";
import { GridTextField } from "../form/GridTextField";
import CircularProgress from "@mui/material/CircularProgress";
import { SaveButton } from "../form/GridButton";
import { arrayToOptions, GridSelect, toUpperFirstLetter } from "../form/GridSelect";

const Form = ({ user, changed, onChange, saveChanges }) => (
  <Grid container spacing={2}>
    <GridTextField label="Name" id="name" entity={user} onChange={onChange} required />
    <GridTextField label="Email" id="email" entity={user} onChange={onChange} disabled />
    <GridSelect
      label="Email Verified"
      id="email_verified"
      value={String(user.email_verified).toLowerCase() === "true" ? "yes" : "no"}
      onChange={({ target: { name, value } }) =>
        onChange({ target: { name, value: value === "yes" } })
      }
      options={arrayToOptions(["yes", "no"], toUpperFirstLetter)}
    />
    <GridSelect
      label="Group"
      id="custom:userGroup"
      value={user["custom:userGroup"] ?? "GUEST"}
      onChange={onChange}
      options={arrayToOptions(["GUEST", "STAFF", "ADMIN"])}
    />
    <GridSelect
      label="Enabled?"
      id="enabled"
      value={user.enabled ? "Yes" : "No"}
      options={arrayToOptions(["Yes", "No"])}
      disabled
    />
    <GridTextField label="Status" id="status" entity={user} disabled />
    <SaveButton onClick={saveChanges} disabled={!changed} />
  </Grid>
);

export const UserEditForm = ({ user, setUser }) => {
  const { apiPut } = useApi();
  const { setNotifications, success, error } = useNotifications();
  const [changedFields, setChangedFields] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      setUser({ ...user, [name]: value });
      setChangedFields({ ...changedFields, [name === "custom:userGroup" ? "group" : name]: value });
    },
    [user, setUser, changedFields, setChangedFields]
  );

  const saveChanges = useCallback(
    (event) => {
      event.preventDefault();
      setSubmitting(true);
      apiPut(`/admin/cognito/user/${user.username}`, { data: changedFields })
        .then(() => {
          setNotifications([success("User record has been updated.")]);
          setChangedFields({});
        })
        .catch((err) => setNotifications([error(err.message)]))
        .finally(() => setSubmitting(false));
    },
    [
      apiPut,
      user,
      setUser,
      changedFields,
      setChangedFields,
      setNotifications,
      error,
      success,
      setSubmitting,
    ]
  );

  return !submitting ? (
    <Form
      user={user}
      onChange={onChange}
      saveChanges={saveChanges}
      changed={!!Object.keys(changedFields).length}
    />
  ) : (
    <CircularProgress />
  );
};
