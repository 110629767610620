import React, { useState } from "react";
import useApi from "../../hooks/useApi";
import Button from "@mui/material/Button";
import { useNotifications } from "../../hooks/useNotifications";
import CircularProgress from "@mui/material/CircularProgress";

export const UserEnabledToggle = ({ user, setUser, sx }) => {
  const { apiPost } = useApi();
  const { setNotifications, success, error } = useNotifications();
  const [submitting, setSubmitting] = useState(false);

  const onClick = (event) => {
    event.preventDefault();
    setSubmitting(true);
    apiPost(`/admin/cognito/user/${user.username}/${user.enabled ? "disable" : "enable"}`, {})
      .then(() => {
        setNotifications([success(`User has been ${user.enabled ? "disabled" : "enabled"}.`)]);
        setUser({ ...user, enabled: !user.enabled });
      })
      .catch((err) => setNotifications([error(err.message)]))
      .finally(() => setSubmitting(false));
  };

  return submitting ? (
    <CircularProgress />
  ) : (
    <Button
      variant="contained"
      onClick={onClick}
      color={user.enabled ? "error" : "primary"}
      sx={sx}
    >
      {user.enabled ? "Disable" : "Enable"}
    </Button>
  );
};
