import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { useUser } from "../hooks/useUser";
import CircularProgress from "@mui/material/CircularProgress";
import { NarrowPage } from "../components/layout/NarrowPage";

export const RootPage = () => {
  const { push } = useHistory();
  const { user } = useUser();

  useEffect(() => {
    if (!user) {
      push("/suggest-guest");
    } else if (user.isStaff) {
      push("/suggested-guests");
    } else {
      push("/my-guest-profile");
    }
  }, [user, push]);

  return (
    <NarrowPage>
      <CircularProgress />
    </NarrowPage>
  );
};
