import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useUser } from "../../hooks/useUser";
import Box from "@mui/material/Box";
import useApi from "../../hooks/useApi";
import CircularProgress from "@mui/material/CircularProgress";
import { useNotifications } from "../../hooks/useNotifications";
import { GridTextField } from "../form/GridTextField";
import { GridTextAreaField } from "../form/GridTextAreaField";
import { GridSelect } from "../form/GridSelect";

const GridHeader = ({ children }) => (
  <Grid item xs={12}>
    <Typography component="h2" variant="h4" sx={{ mt: 5, mb: 1 }}>
      {children}
    </Typography>
  </Grid>
);

const SubmitterInformationFields = () => (
  <Grid container spacing={2}>
    <GridHeader>Submitter Information</GridHeader>
    <GridTextField label="Name" id="submitted_by_name" required defaultValue="" />
    <GridTextField label="Email" id="submitted_by_email" type="email" required defaultValue="" />
    <GridSelect
      label="Submitter Works for a PR firm?"
      id="submitted_by_PR_firm"
      required
      options={[
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ]}
      helperText="Does the submitter work for a PR firm? The submission will be reviewed by the Security Weekly team."
      defaultValue=""
      sm={12}
    />
  </Grid>
);

const GuestInformationFields = ({ isStaff }) => (
  <Grid container spacing={2}>
    <GridHeader>Guest Information</GridHeader>
    <GridTextField label="First Name" id="firstname" required defaultValue="" />
    <GridTextField label="Last Name" id="lastname" required={isStaff} defaultValue="" />
    <GridTextField label="Email" id="email" type="email" required={isStaff} defaultValue="" />
    <GridSelect
      label="Works for vendor?"
      id="works_for_vendor"
      required
      options={[
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ]}
      defaultValue=""
      sm={12}
      helperText="Does the guest you are suggesting work for a security vendor? While this does not disqualify them from coming on the show we have to be certain the appearance will not be used as a sales pitch. They may qualify for a free or paid interview depending on the situation. The submission will be reviewed by the Security Weekly team."
    />
    <GridSelect
      label="Is it ok to contact this guest directly via email?"
      id="open_for_direct_contact"
      required
      options={[
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ]}
      defaultValue=""
      sm={12}
    />
    <GridTextField label="Job Title" id="job_title" defaultValue="" />
    <GridTextField label="Company Name" id="company" defaultValue="" />
    <GridTextField label="Phone Number" id="phone" defaultValue="" />
    <GridTextField label="Twitter" id="twitter" defaultValue="" />
    <GridTextField label="LinkedIn" id="linkedin" defaultValue="" />
    <GridTextField label="Discord" id="discord" defaultValue="" />
    <GridTextField label="Github" id="github" defaultValue="" />
    <GridTextField label="Website" id="website" defaultValue="" />
    <GridTextAreaField label="Bio" id="bio" required defaultValue="" />
  </Grid>
);

const AppearanceInformationFields = ({ shows }) => (
  <Grid container spacing={2}>
    <GridHeader>Appearance Information</GridHeader>
    <GridTextAreaField
      label="Reason"
      id="reason"
      required
      helperText="Why do you believe this person would make a great guest for our shows?"
      defaultValue=""
    />
    <GridTextField
      label="Topic"
      id="suggested_topic"
      required
      sm={12}
      helperText={
        <>
          Please describe the topic that will be discussed. This can be generic (e.g. &quot;security
          research&quot;) or specific (e.g. &quot;Wordpress exploits&quot;).
        </>
      }
      defaultValue=""
    />
    <GridSelect
      label="Suggest a Show for guest to appear on"
      id="suggested_show"
      required
      options={shows.map((show) => ({ value: show, label: show }))}
      defaultValue=""
      helperText="Please make your best guess at which show would be most appropriate for the guest."
      sm={12}
    />
    <GridSelect
      label="Segment Type"
      id="suggested_segment_type"
      required
      options={[
        { value: "interview", label: "Interview" },
        { value: "technical", label: "Technical Segment" },
      ]}
      defaultValue=""
      helperText="Interviews are more like a relaxed conversation and technical segments show someone how to do something."
      sm={12}
    />
  </Grid>
);

const InnerForm = ({ user, shows }) => (
  <>
    <Typography component="p" variant="subtitle1">
      For a list of preferred show topics, check out{" "}
      <Link
        rel="noopener noreferrer"
        href="https://securityweekly.com/content-plan-for-security-weekly"
        target="_blank"
      >
        Paul&apos;s list
      </Link>
      !
    </Typography>
    <Typography component="p" variant="subtitle1">
      For suggestion guidelines, please visit our{" "}
      <Link
        rel="noopener noreferrer"
        href="https://securityweekly.com/security-weekly-appearance-guidelines"
        target="_blank"
      >
        appearance guidelines
      </Link>
      .
    </Typography>
    <Typography component="p" variant="subtitle1" sx={{ mt: 2 }}>
      Fields marked * are required.
    </Typography>
    {!user?.isStaff ? <SubmitterInformationFields /> : null}
    <GuestInformationFields isStaff={user?.isStaff} />
    <AppearanceInformationFields shows={shows} />
    <Box sx={{ mt: 3 }}>
      <Button fullWidth type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </Box>
  </>
);

export const SuggestGuestForm = () => {
  const { user } = useUser();
  const { apiPost } = useApi();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { setNotifications, error, success } = useNotifications();

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const isStaff = user?.isStaff;
      const formData = new FormData(event.currentTarget);
      const data = {
        submitted_by_name: isStaff ? user.name : formData.get("submitted_by_name"),
        submitted_by_email: isStaff ? user.email : formData.get("submitted_by_email"),
        submitted_by_PR_firm: isStaff ? false : formData.get("submitted_by_PR_firm") === "true",
        submitted_by_source: isStaff ? "internal" : "external",
        firstname: formData.get("firstname"),
        lastname: formData.get("lastname"),
        email: formData.get("email"),
        works_for_vendor: formData.get("works_for_vendor") === "true",
        open_for_direct_contact: formData.get("open_for_direct_contact") === "true",
        job_title: formData.get("job_title"),
        company: formData.get("company"),
        phone: formData.get("phone"),
        twitter: formData.get("twitter"),
        linkedin: formData.get("linkedin"),
        discord: formData.get("discord"),
        github: formData.get("github"),
        website: formData.get("website"),
        bio: formData.get("bio"),
        reason: formData.get("reason"),
        suggested_topic: formData.get("suggested_topic"),
        suggested_show: formData.get("suggested_show"),
        suggested_segment_type: formData.get("suggested_segment_type"),
        approval_status: "suggested",
      };
      if (isStaff) {
        data.approval_status = data.email ? "suggested" : "requested";
      }

      setSubmitting(true);
      apiPost("/guest/suggested", { data })
        .then(() => {
          setNotifications([success("Thank you for suggesting a guest!")]);
        })
        .catch((err) => {
          setNotifications([
            error("An error has occurred submitting a guest suggestion. Please, try again later."),
          ]);
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .finally(() => {
          setSubmitting(false);
          setSubmitted(true);
        });
    },
    [user, apiPost, setSubmitting, setSubmitted, setNotifications, error, success]
  );

  const shows = [
    "Application Security Weekly",
    "Business Security Weekly",
    "The CISO Stories Podcast",
    "Enterprise Security Weekly",
    "Paul's Security Weekly",
  ];

  return (
    <form onSubmit={onSubmit}>
      {submitting ? (
        <CircularProgress />
      ) : submitted ? (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSubmitted(false);
              setNotifications([]);
            }}
          >
            Suggest Another Guest
          </Button>
        </>
      ) : (
        <InnerForm user={user} shows={shows} />
      )}
    </form>
  );
};
