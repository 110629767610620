import { useHistory } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { AuthPage } from "../../components/auth/AuthPage";
import {
  AuthButton,
  AuthForm,
  AuthTextField,
  passwordHelperText,
} from "../../components/auth/AuthForm";
import { useNotifications } from "../../hooks/useNotifications";

const Form = ({ onSubmit }) => (
  <AuthForm
    onSubmit={onSubmit}
    links={[
      { path: "/auth/sign-in", label: "Sign In" },
      { path: "/auth/reset-password", label: "Reset Password" },
      { path: "/auth/confirm-account", label: "Confirm Account" },
    ]}
  >
    <AuthTextField id="email" label="Email" type="email" autoComplete="email" />
    <AuthTextField
      id="password"
      label="Password"
      type="password"
      autoComplete="new-password"
      helperText={passwordHelperText}
    />
    <AuthTextField id="name" label="Name" autoComplete="name" />
    <AuthButton label="Sign Up" />
  </AuthForm>
);

export const AuthSignUpPage = () => {
  const { signUp } = useUser();
  const [progress, setProgress] = useState(false);
  const { push } = useHistory();
  const { setNotifications, error, success } = useNotifications();

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      setProgress(true);
      try {
        await signUp({
          email: formData.get("email"),
          password: formData.get("password"),
          name: formData.get("name"),
        });
        setNotifications([
          success(
            "Your account has been created. Before being able to sign in you need to confirm your email. Please, enter the confirmation code here."
          ),
        ]);
        push("/auth/confirm-account");
      } catch (err) {
        setNotifications([error(err.message)]);
      } finally {
        setProgress(false);
      }
    },
    [setProgress, signUp, push, setProgress, setNotifications, error, success]
  );

  return <AuthPage title="Sign Up" progress={progress} Form={Form} onSubmit={onSubmit} />;
};
