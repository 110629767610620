import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNotifications } from "../../hooks/useNotifications";

export const NarrowPage = ({ title, children, maxWidth }) => {
  const { Notifications } = useNotifications();
  return (
    <Container component="main" maxWidth={maxWidth ?? "md"} sx={{ my: 8 }}>
      <Paper variant="elevation" sx={{ padding: 3 }}>
        {title ? (
          <>
            <Typography component="h1" variant="h4" sx={{ mb: 2 }}>
              {title}
            </Typography>
          </>
        ) : null}
        <Notifications sx={{ my: 2 }} />
        {children}
      </Paper>
    </Container>
  );
};
