import React, { createContext, useCallback, useState } from "react";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";

export const NotificationsContext = createContext(null);

const sameNotifications = (a, b) => a.text === b.text && a.severity === b.severity;

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const error = useCallback((text) => ({ text, severity: "error" }), []);
  const success = useCallback((text) => ({ text, severity: "success" }), []);
  const warning = useCallback((text) => ({ text, severity: "warning" }), []);
  const info = useCallback((text) => ({ text, severity: "info" }), []);

  const Notifications = ({ sx }) => {
    const onClose = (notification) => {
      setNotifications(
        notifications.filter((_notification) => !sameNotifications(_notification, notification))
      );
    };
    return (
      <Box sx={sx}>
        {notifications.map((notification) => (
          <Alert
            key={`${notification.severity}: ${notification.text}`}
            severity={notification.severity}
            onClose={() => onClose(notification)}
            sx={{ mb: 1 }}
          >
            {notification.text}
          </Alert>
        ))}
      </Box>
    );
  };

  return (
    <NotificationsContext.Provider
      value={{ setNotifications, Notifications, error, success, warning, info }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
