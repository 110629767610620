import React, {useCallback, useState} from "react";
import useApi from "../../hooks/useApi";
import {useNotifications} from "../../hooks/useNotifications";
import Grid from "@mui/material/Grid";
import {GridTextField} from "../form/GridTextField";
import CircularProgress from "@mui/material/CircularProgress";
import {GridTextAreaField} from "../form/GridTextAreaField";
import {GridDivider} from "../form/GridDivider";
import {SaveButton} from "../form/GridButton";

const Form = ({ guest, changed, onChange, saveChanges, suggestedGuest }) => (
  <Grid container spacing={2}>
    <GridTextField
      label="First Name"
      id="firstname"
      entity={guest}
      onChange={onChange}
      required
      error={suggestedGuest && suggestedGuest.firstname !== guest.firstname}
    />
    <GridTextField
      label="Last Name"
      id="lastname"
      entity={guest}
      onChange={onChange}
      required
      error={suggestedGuest && suggestedGuest.lastname !== guest.lastname}
    />
    <GridTextField
      label="Job Title"
      id="title"
      entity={guest}
      onChange={onChange}
      error={suggestedGuest && suggestedGuest.job_title !== guest.title}
    />
    <GridTextField
      label="Company"
      id="company"
      entity={guest}
      onChange={onChange}
      error={suggestedGuest && suggestedGuest.company !== guest.company}
    />
    <GridTextAreaField
      label="Bio"
      id="bio"
      entity={guest}
      onChange={onChange}
      error={suggestedGuest && suggestedGuest.bio !== guest.bio}
    />
    <GridDivider />
    <GridTextField label="Email" id="email" entity={guest} onChange={onChange} disabled />
    <GridTextField
      label="Secondary Email"
      id="secondary_email"
      entity={guest}
      onChange={onChange}
      error={suggestedGuest && suggestedGuest.submitted_by_email !== guest.secondary_email}
    />
    <GridTextField
      label="Phone"
      id="phone"
      entity={guest}
      onChange={onChange}
      error={suggestedGuest && suggestedGuest.phone !== guest.phone}
    />
    <GridTextField
      label="Website"
      id="website"
      entity={guest}
      onChange={onChange}
      error={suggestedGuest && suggestedGuest.website !== guest.website}
    />
    <GridTextField
      label="Twitter"
      id="twitter"
      entity={guest}
      onChange={onChange}
      error={suggestedGuest && suggestedGuest.twitter !== guest.twitter}
    />
    <GridTextField
      label="Linkedin"
      id="linkedin"
      entity={guest}
      onChange={onChange}
      error={suggestedGuest && suggestedGuest.linkedin !== guest.linkedin}
    />
    <GridTextField
      label="Github"
      id="github"
      entity={guest}
      onChange={onChange}
      error={suggestedGuest && suggestedGuest.github !== guest.github}
    />
    <GridTextField
      label="Discord"
      id="discord"
      entity={guest}
      onChange={onChange}
      error={suggestedGuest && suggestedGuest.discord !== guest.discord}
    />
    <GridTextField label="Skype" id="skype" entity={guest} onChange={onChange} />
    <SaveButton onClick={saveChanges} disabled={!changed} />
  </Grid>
);

export const GuestForm = ({ guest, setGuest, suggestedGuest }) => {
  const { apiPut } = useApi();
  const { setNotifications, success, error } = useNotifications();
  const [changedFields, setChangedFields] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      setGuest({ ...guest, [name]: value });
      setChangedFields({ ...changedFields, [name]: value });
    },
    [guest, setGuest, changedFields, setChangedFields]
  );

  const saveChanges = useCallback(
    (event) => {
      event.preventDefault();
      setSubmitting(true);
      apiPut(`/guest/${guest.id}`, { data: changedFields })
        .then(({ data }) => {
          setNotifications([success("Guest record has been updated.")]);
          setGuest(data);
          setChangedFields({});
        })
        .catch((err) => setNotifications([error(err.message)]))
        .finally(() => setSubmitting(false));
    },
    [
      apiPut,
      guest,
      setGuest,
      changedFields,
      setChangedFields,
      setNotifications,
      error,
      success,
      setSubmitting,
    ]
  );

  return !submitting ? (
    <Form
      guest={guest}
      onChange={onChange}
      saveChanges={saveChanges}
      changed={!!Object.keys(changedFields).length}
      suggestedGuest={suggestedGuest}
    />
  ) : (
    <CircularProgress />
  );
};
