import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NarrowPage } from "../../../components/layout/NarrowPage";
import useApi from "../../../hooks/useApi";
import CircularProgress from "@mui/material/CircularProgress";
import { useUser } from "../../../hooks/useUser";
import { AccessDeniedPage } from "../../../components/layout/AccessDeniedPage";
import { useNotifications } from "../../../hooks/useNotifications";
import { NotFoundPage } from "../../../components/layout/NotFoundPage";
import { UserEditForm } from "../../../components/user/UserEditForm";
import { UserEnabledToggle } from "../../../components/user/UserEnabledToggle";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { UserDeleteButton } from "../../../components/user/UserDeleteButton";

export const AdminUserIdPage = () => {
  const { id } = useParams();
  const { user: adminUser } = useUser();

  const { apiGet } = useApi();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setNotifications, error } = useNotifications();

  useEffect(() => {
    if (!adminUser || !loading) return;

    apiGet(`/admin/cognito/user/${id}`)
      .then(({ data }) => {
        setUser(data);
      })
      .catch((err) => setNotifications([error(err.message)]))
      .finally(() => setLoading(false));
  }, [id, apiGet, setUser, loading, setLoading, setNotifications, error]);

  if (!adminUser || !adminUser.isAdmin) return <AccessDeniedPage />;
  if (loading)
    return (
      <NarrowPage title="Edit User">
        <CircularProgress />
      </NarrowPage>
    );
  if (!user) return <NotFoundPage />;

  return (
    <NarrowPage title="Edit User">
      <UserEditForm user={user} setUser={setUser} />
      <Divider sx={{ my: 4 }} />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <UserEnabledToggle user={user} setUser={setUser} sx={{ mb: 2, width: 200 }} />
        <UserDeleteButton user={user} sx={{ mb: 2, width: 200 }} />
      </Box>
    </NarrowPage>
  );
};
