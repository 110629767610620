import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { useNotifications } from "../hooks/useNotifications";

export const UserContext = createContext(null);

const pool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
});

export const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);
  const [signUpEmail, setSignUpEmail] = useState("");
  const { setNotifications, error } = useNotifications();

  // Init session and user.
  useEffect(() => {
    if (!loading) return;

    const cognitoUser = pool.getCurrentUser();
    if (!cognitoUser) return setLoading(false);

    let timer = null;
    cognitoUser.getSession((err, session) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.error(err.message);
        setLoading(false);
        return;
      }
      if (session) {
        setSession(session);
        setLoading(false);
        const expiresInSeconds = session.getIdToken().getExpiration() - Date.now() / 1000;
        // eslint-disable-next-line no-console
        console.log(`Expires in ${expiresInSeconds}`);
        timer = setTimeout(() => {
          setNotifications([error("User session has expired. Please, reload the page.")]);
          cognitoUser.refreshSession(session.getRefreshToken(), (err, result) => {
            // eslint-disable-next-line no-console
            if (err) console.error(err);
            // TODO Figure out what is in result and if it's a valid session object that can be set here right away.
            // eslint-disable-next-line no-console
            console.log(result);
            setSession(null);
            setLoading(true);
          });
        }, expiresInSeconds * 1000);
      }
    });
    return () => timer !== null && clearTimeout(timer);
  }, [loading, setSession, setLoading, setNotifications, error]);

  const user = useMemo(() => {
    if (!session) return null;

    const idToken = session.getIdToken();
    const payload = idToken.payload;
    const userGroup = payload["custom:userGroup"];
    const isStaff = ["STAFF", "ADMIN"].includes(userGroup);
    return {
      idToken: idToken.getJwtToken(),
      email: payload.email,
      name: payload.name,
      isGuest: !isStaff,
      isStaff,
      isAdmin: userGroup === "ADMIN",
    };
  }, [session]);

  // Functions needed for Sign In & Sign Out workflows.
  const signIn = useCallback(
    (email, password) =>
      new Promise((resolve, reject) => {
        setSignUpEmail(email);
        const cognitoUser = new CognitoUser({
          Username: email,
          Pool: pool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });

        cognitoUser.authenticateUser(authDetails, {
          onSuccess: (session) => {
            setSession(session);
            setSignUpEmail("");
            resolve();
          },
          onFailure: (error) => reject({ reason: "failure", error }),
          newPasswordRequired: (userAttributes, requiredAttributes) =>
            reject({ reason: "passwordRequired", userAttributes, requiredAttributes, cognitoUser }),
          mfaRequired: (challengeName, challengeParameters) =>
            reject({ reason: "mfaRequired", challengeName, challengeParameters }),
          totpRequired: (challengeName, challengeParameters) =>
            reject({ reason: "totpRequired", challengeName, challengeParameters }),
          customChallenge: (challengeParameters) =>
            reject({ reason: "customChallenge", challengeParameters }),
          mfaSetup: (challengeName, challengeParameters) =>
            reject({ reason: "mfaSetup", challengeName, challengeParameters }),
          selectMFAType: (challengeName, challengeParameters) =>
            reject({ reason: "selectMFAType", challengeName, challengeParameters }),
        });
      }),
    [setSession, setSignUpEmail]
  );

  const completeNewPasswordChallenge = useCallback(
    (cognitoUser, newPassword) =>
      new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(
          newPassword,
          {},
          {
            onSuccess: (session) => {
              setSession(session);
              setSignUpEmail("");
              resolve();
            },
            onFailure: (error) => reject({ reason: "failure", error }),
            newPasswordRequired: (userAttributes, requiredAttributes) =>
              reject({
                reason: "passwordRequired",
                userAttributes,
                requiredAttributes,
                cognitoUser,
              }),
          }
        );
      }),
    [setSignUpEmail]
  );

  const signOut = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const cognitoUser = pool.getCurrentUser();
        if (!cognitoUser) return reject(new Error("Trying to sign out when not signed in!"));

        cognitoUser.signOut(() => {
          setSession(null);
          resolve();
        });
      }),
    [setSession]
  );

  // Functions needed for Sign Up workflow.
  const signUp = useCallback(
    ({ email, password, name }) =>
      new Promise((resolve, reject) => {
        setSignUpEmail(email);
        pool.signUp(
          email,
          password,
          [
            {
              Name: "email",
              Value: email,
            },
            {
              Name: "name",
              Value: name,
            },
          ],
          null,
          (err, data) => {
            if (err) return reject(err);
            if (!data) return reject(new Error("Missing sign up data without any errors!"));
            resolve(data);
          }
        );
      }),
    [setSignUpEmail]
  );

  const confirmRegistration = useCallback(
    (email, code) =>
      new Promise((resolve, reject) => {
        setSignUpEmail(email);
        const cognitoUser = new CognitoUser({ Username: email, Pool: pool });
        cognitoUser.confirmRegistration(code, true, (err, result) => {
          if (err) return reject(err);
          resolve(result);
        });
      }),
    [setSignUpEmail]
  );

  // Functions needed for Forgot Password workflow.
  const forgotPassword = useCallback(
    (email) =>
      new Promise((resolve, reject) => {
        setSignUpEmail(email);
        const cognitoUser = new CognitoUser({ Username: email, Pool: pool });
        cognitoUser.forgotPassword({
          onSuccess: (data) => resolve(data),
          onFailure: (error) => reject(error),
        });
      }),
    [setSignUpEmail]
  );

  const resendConfirmationCode = useCallback(
    (email) =>
      new Promise((resolve, reject) => {
        setSignUpEmail(email);
        const cognitoUser = new CognitoUser({ Username: email, Pool: pool });
        cognitoUser.resendConfirmationCode((error, result) => {
          if (error) return reject(error);
          resolve(result);
        });
      }),
    [setSignUpEmail]
  );

  const confirmPassword = useCallback(
    (email, code, password) =>
      new Promise((resolve, reject) => {
        setSignUpEmail(email);
        const cognitoUser = new CognitoUser({ Username: email, Pool: pool });
        cognitoUser.confirmPassword(code, password, {
          onSuccess: (success) => resolve(success),
          onFailure: (error) => reject(error),
        });
      }),
    [setSignUpEmail]
  );

  // Render Provider component.
  return (
    <UserContext.Provider
      value={{
        loading,
        user,
        signUpEmail,
        signIn,
        completeNewPasswordChallenge,
        signOut,
        signUp,
        confirmRegistration,
        forgotPassword,
        resendConfirmationCode,
        confirmPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
