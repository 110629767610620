import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { NarrowPage } from "../layout/NarrowPage";
import { useUser } from "../../hooks/useUser";

export const AuthPage = ({ progress, Form, onSubmit, error, title }) => {
  const { signUpEmail } = useUser();
  return (
    <NarrowPage title={title} maxWidth="sm">
      {progress ? (
        <CircularProgress />
      ) : (
        <Form onSubmit={onSubmit} error={error} email={signUpEmail} />
      )}
    </NarrowPage>
  );
};
