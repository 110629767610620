import React, { useEffect, useState } from "react";
import { useUser } from "../hooks/useUser";
import NoGuest from "../components/guest/NoGuest";
import { GuestForm } from "../components/guest/GuestForm";
import useApi from "../hooks/useApi";
import CircularProgress from "@mui/material/CircularProgress";
import { NarrowPage } from "../components/layout/NarrowPage";
import { AccessDeniedPage } from "../components/layout/AccessDeniedPage";
import { useNotifications } from "../hooks/useNotifications";
import { GuestHeadshotForm } from "../components/guest/GuestHeadshotForm";

export const MyGuestProfilePage = () => {
  const { user } = useUser();
  const { apiGet } = useApi();
  const { setNotifications, error } = useNotifications();
  const [guest, setGuest] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user || !loading) return;

    apiGet(`/guest/email/${user.email}`)
      .then(({ data }) => {
        if (data.length) {
          setGuest(data[0]);
        }
      })
      .catch((err) => {
        if (err.message === "Access denied.") return;
        setNotifications([error(err.message)]);
      })
      .finally(() => setLoading(false));
  }, [user, apiGet, setGuest, loading, setLoading, setNotifications]);

  if (!user) return <AccessDeniedPage />;
  if (loading) return <CircularProgress />;

  return (
    <NarrowPage title="My Guest Profile">
      {guest ? (
        <>
          <GuestHeadshotForm guest={guest} setGuest={setGuest} />
          <GuestForm guest={guest} setGuest={setGuest} />
        </>
      ) : (
        <NoGuest />
      )}
    </NarrowPage>
  );
};

export default MyGuestProfilePage;
