import { useHistory } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { AuthPage } from "../../components/auth/AuthPage";
import { AuthButton, AuthForm, AuthTextField } from "../../components/auth/AuthForm";
import { useNotifications } from "../../hooks/useNotifications";

const Form = ({ onSubmit, email }) => (
  <AuthForm
    onSubmit={onSubmit}
    links={[
      { path: "/auth/sign-in", label: "Sign In" },
      { path: "/auth/sign-up", label: "Create Account" },
      { path: "/auth/confirm-account", label: "Confirm Account" },
    ]}
  >
    <AuthTextField
      id="email"
      label="Email"
      type="email"
      autoComplete="email"
      defaultValue={email}
    />
    <AuthButton label="Reset Password" />
  </AuthForm>
);

export const AuthResetPasswordPage = () => {
  const { forgotPassword } = useUser();
  const [progress, setProgress] = useState(false);
  const { setNotifications, error, info } = useNotifications();
  const { push } = useHistory();

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      setProgress(true);
      try {
        await forgotPassword(formData.get("email"));
        push("/auth/reset-password-confirm");
        setNotifications([
          info("A password reset code has be sent to your email if you have an account."),
        ]);
      } catch (err) {
        setNotifications([error(err.message)]);
      } finally {
        setProgress(false);
      }
    },
    [setProgress, forgotPassword, push, setNotifications, error, info, setProgress]
  );

  return <AuthPage title="Reset Password" progress={progress} Form={Form} onSubmit={onSubmit} />;
};
