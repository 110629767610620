import { useHistory } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { AuthPage } from "../../components/auth/AuthPage";
import { AuthButton, AuthForm, AuthTextField } from "../../components/auth/AuthForm";
import { useNotifications } from "../../hooks/useNotifications";

const SignInForm = ({ onSubmit, email }) => (
  <AuthForm
    onSubmit={onSubmit}
    links={[
      { path: "/auth/reset-password", label: "Reset Password" },
      { path: "/auth/sign-up", label: "Create Account" },
      { path: "/auth/confirm-account", label: "Confirm Account" },
    ]}
  >
    <AuthTextField
      id="email"
      label="Email"
      type="email"
      autoComplete="email"
      defaultValue={email}
    />
    <AuthTextField id="password" label="Password" type="password" autoComplete="current-password" />
    <AuthButton label="Sign In" />
  </AuthForm>
);

const NewPasswordForm = ({ onSubmit }) => (
  <AuthForm onSubmit={onSubmit}>
    <AuthTextField id="password" label="New Password" type="password" autoComplete="new-password" />
    <AuthButton label="Set New Password" />
  </AuthForm>
);

export const AuthSignInPage = () => {
  const { signIn, completeNewPasswordChallenge } = useUser();
  const [progress, setProgress] = useState(false);
  const { push } = useHistory();
  const { setNotifications, error, warning } = useNotifications();
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  const onSignInSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setProgress(true);
      const formData = new FormData(event.currentTarget);
      try {
        await signIn(formData.get("email"), formData.get("password"));
        setNotifications([]);
        push("/");
      } catch (err) {
        switch (err.reason) {
          case "failure":
            setNotifications([error(err.error.message)]);
            if (err.error.message === "Password reset required for the user")
              push("/auth/reset-password");
            break;
          case "passwordRequired":
            setCognitoUser(err.cognitoUser);
            setNewPasswordRequired(true);
            setNotifications([warning("New password is required!")]);
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(err);
            setNotifications([error("MFA required.")]);
            break;
        }
      } finally {
        setProgress(false);
      }
    },
    [signIn, push, setProgress, setNotifications, error, warning, setNewPasswordRequired]
  );

  const onNewPasswordSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setProgress(true);
      const formData = new FormData(event.currentTarget);
      try {
        await completeNewPasswordChallenge(cognitoUser, formData.get("password"));
        setNotifications([]);
        push("/");
      } catch (err) {
        switch (err.reason) {
          case "failure":
            setNotifications([error(err.error.message)]);
            break;
          case "passwordRequired":
            setNotifications([warning("New password is still required!")]);
            setCognitoUser(err.cognitoUser);
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(err);
            setNotifications([error("MFA required.")]);
            break;
        }
      } finally {
        setProgress(false);
      }
    },
    [
      completeNewPasswordChallenge,
      cognitoUser,
      push,
      setProgress,
      setNotifications,
      error,
      warning,
      setNewPasswordRequired,
    ]
  );

  return (
    <AuthPage
      title="Sign In"
      progress={progress}
      Form={newPasswordRequired ? NewPasswordForm : SignInForm}
      onSubmit={newPasswordRequired ? onNewPasswordSubmit : onSignInSubmit}
    />
  );
};
