import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { UserProvider } from "./providers/UserProvider";
import "./App.css";
import { useUser } from "./hooks/useUser";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import { Navbar } from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import useRoutes from "./hooks/useRoutes";
import { NotificationsProvider } from "./providers/NotificationsProvider";

const VERSION = process.env.REACT_APP_VERSION;

const Routes = () => {
  const { routes } = useRoutes();

  return (
    <Switch>
      {routes.map(({ access, Component, path }) => (
        <Route key={`${path}-${access}`} component={Component} path={path} />
      ))}
    </Switch>
  );
};

const ProviderAwareApp = () => {
  const { loading } = useUser();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (loading) return <CircularProgress />;

  return (
    <>
      <CssBaseline />
      <Navbar />
      <Routes />
      <Footer version={VERSION} />
    </>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#0076e3",
      light: "#2da6ff",
      dark: "#0145B2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#bb840f",
      light: "#cba54b",
      dark: "#b26e01",
      contrastText: "#fff",
    },
  },
});

export const App = () => (
  <BrowserRouter>
    <NotificationsProvider>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <ProviderAwareApp version={VERSION} />
        </ThemeProvider>
      </UserProvider>
    </NotificationsProvider>
  </BrowserRouter>
);
