import {useUser} from "./useUser";
import {useCallback} from "react";

const API_HOST = process.env.REACT_APP_TARGET_URL;

export const useApi = () => {
  const { user } = useUser();

  const request = useCallback(
    async (endpoint, options) => {
      options["headers"] = {
        Accept: "application/json",
      };
      if (user) {
        options["headers"]["Authorization"] = `Bearer ${user.idToken}`;
      }
      if (options.body && !(options.body instanceof FormData)) {
        options["headers"]["Content-Type"] = "application/json";
      }
      const response = await fetch(`https://${API_HOST}${endpoint}`, options);
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    },
    [user]
  );

  const apiGet = useCallback(async (endpoint) => request(endpoint, {}), [request]);

  const apiPost = useCallback(
    async (endpoint, data) =>
      request(endpoint, {
        method: "POST",
        body: data instanceof FormData ? data : JSON.stringify(data),
      }),
    [request]
  );

  const apiPut = useCallback(
    async (endpoint, data) =>
      request(endpoint, {
        method: "PUT",
        body: data instanceof FormData ? data : JSON.stringify(data),
      }),
    [request]
  );

  const apiDelete = useCallback(
    async (endpoint) =>
      request(endpoint, {
        method: "DELETE",
      }),
    [request]
  );

  const createGuestFromSuggested = useCallback(
    (suggested) => ({
      firstname: suggested.firstname,
      lastname: suggested.lastname,
      company: suggested.company,
      title: suggested.job_title,
      email: suggested.email,
      secondary_email: suggested.email !== suggested.submitted_by_email ? suggested.submitted_by_email : null,
      phone: suggested.phone,
      bio: suggested.bio,
      skype: suggested.skype,
      website: suggested.website,
      twitter: suggested.twitter,
      discord: suggested.discord,
      instagram: suggested.instagram,
      linkedin: suggested.linkedin,
      github: suggested.github,
      topic: suggested.suggested_topic,
      status: suggested.approval_status,
      host_notes: suggested.host_notes,
      registrationEmailSent: suggested.onboarding_email_sent,
    }),
    []
  );

  return { apiGet, apiPost, apiPut, apiDelete, createGuestFromSuggested };
};

export default useApi;
