import React from "react";
import Container from "@mui/material/Container";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

const TrueFalseIcon = ({ value }) =>
  String(value).toLowerCase() === "true" ? (
    <CheckCircleRoundedIcon color="success" />
  ) : (
    <CancelRoundedIcon color="error" />
  );

export const UsersTable = ({ users }) => {
  const columns = [
    {
      field: "username",
      headerName: "Username",
      minWidth: 300,
      renderCell: ({ row: { username } }) => (
        <Link component={RouterLink} to={`/admin/user/${username}`}>
          {username}
        </Link>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 300,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 300,
      sortable: true,
    },
    {
      field: "email_verified",
      headerName: "Email Verified",
      minWidth: 50,
      sortable: true,
      renderCell: ({ row: { email_verified } }) => <TrueFalseIcon value={email_verified} />,
    },
    {
      field: "enabled",
      headerName: "Enabled",
      minWidth: 50,
      sortable: true,
      renderCell: ({ row: { enabled } }) => <TrueFalseIcon value={enabled} />,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 50,
      sortable: true,
    },
    {
      field: "custom:userGroup",
      headerName: "Group",
      minWidth: 50,
      sortable: true,
      valueGetter: ({ row }) => row["custom:userGroup"] || "GUEST",
    },
    {
      field: "created_at",
      headerName: "Created At",
      sortable: true,
      minWidth: 250,
    },
    {
      field: "last_modified_at",
      headerName: "Updated At",
      sortable: true,
      minWidth: 250,
    },
  ];

  return (
    <Container maxWidth={false}>
      <DataGrid
        columns={columns}
        rows={users}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 50, page: 0 } },
          sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
        }}
        pageSizeOptions={[15, 30, 50, 100]}
        slots={{
          toolbar: GridToolbar,
        }}
        density="compact"
        getRowId={(row) => row.sub}
      />
    </Container>
  );
};
