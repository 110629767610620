import { useMemo } from "react";
import { useUser } from "./useUser";
import { MyGuestProfilePage } from "../pages/my-guest-profile";
import { AuthSignInPage } from "../pages/auth/sign-in";
import { AuthConfirmAccountPage } from "../pages/auth/confirm-account";
import { AuthSignUpPage } from "../pages/auth/sign-up";
import { AuthResetPasswordConfirmPage } from "../pages/auth/reset-password-confirm";
import { AuthResetPasswordPage } from "../pages/auth/reset-password";
import { RootPage } from "../pages/root";
import { GuestsPage } from "../pages/guests";
import { SuggestedGuestsPage } from "../pages/suggested-guests";
import { SuggestedGuestIdPage } from "../pages/suggested-guest/[id]";
import { GuestIdPage } from "../pages/guest/[id]";
import { GuestMergePage } from "../pages/guest-merge";
import { SuggestGuestPage } from "../pages/suggest-guest";
import { AdminUsersPage } from "../pages/admin/users";
import { AdminUserIdPage } from "../pages/admin/user/[username]";

const allRoutes = [
  {
    path: "/auth/sign-in",
    name: "Sign In",
    menu: "user",
    Component: AuthSignInPage,
    access: "anonymous",
  },
  {
    path: "/auth/sign-up",
    name: "Sign Up",
    menu: null,
    Component: AuthSignUpPage,
    access: "anonymous",
  },
  {
    path: "/auth/confirm-account",
    name: "Confirm Account",
    menu: null,
    Component: AuthConfirmAccountPage,
    access: "anonymous",
  },
  {
    path: "/auth/reset-password",
    name: "Reset Password",
    menu: null,
    Component: AuthResetPasswordPage,
    access: "anonymous",
  },
  {
    path: "/auth/reset-password-confirm",
    name: "Reset Password Confirm",
    menu: null,
    Component: AuthResetPasswordConfirmPage,
    access: "anonymous",
  },
  {
    path: "/suggest-guest",
    name: "Suggest Guest",
    menu: "navigation",
    Component: SuggestGuestPage,
    access: "any",
  },
  {
    path: "/addSuggestedGuest",
    name: "Suggest Guest",
    menu: null,
    Component: SuggestGuestPage,
    access: "any",
  },
  {
    path: "/suggested-guests",
    name: "Suggested Guests",
    menu: "navigation",
    Component: SuggestedGuestsPage,
    access: "staff",
  },
  {
    path: "/suggested-guest/:id",
    name: "Edit Suggested Guest",
    menu: null,
    Component: SuggestedGuestIdPage,
    access: "staff",
  },
  {
    path: "/suggestedGuest/:id",
    name: "Edit Suggested Guest",
    menu: null,
    Component: SuggestedGuestIdPage,
    access: "staff",
  },
  {
    path: "/guests",
    name: "Guests",
    menu: "navigation",
    Component: GuestsPage,
    access: "staff",
  },
  {
    path: "/guest/:id",
    name: "Edit Guest",
    menu: null,
    Component: GuestIdPage,
    access: "staff",
  },
  {
    path: "/guest-merge/:id/:suggestedGuestId",
    name: "Merge Guest",
    menu: null,
    Component: GuestMergePage,
    access: "staff",
  },
  {
    path: "/my-guest-profile",
    name: "My Guest Profile",
    menu: "navigation",
    Component: MyGuestProfilePage,
    access: "guest",
  },
  {
    path: "/admin/users",
    name: "Users",
    menu: "navigation",
    Component: AdminUsersPage,
    access: "admin",
  },
  {
    path: "/admin/user/:id",
    name: "Edit User",
    menu: null,
    Component: AdminUserIdPage,
    access: "admin",
  },
  {
    path: "/",
    name: "Home",
    menu: null,
    Component: RootPage,
    access: "any",
  },
];

export const useRoutes = () => {
  const { user } = useUser();

  const routes = useMemo(
    () =>
      allRoutes.filter((route) => {
        switch (route.access) {
          case "any":
            return true;
          case "anonymous":
            return !user;
          case "guest":
            return user && user.isGuest;
          case "staff":
            return user && user.isStaff;
          case "admin":
            return user && user.isAdmin;
        }
      }),
    [user]
  );

  return { routes };
};

export default useRoutes;
