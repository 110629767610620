import { useHistory } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { AuthButton, AuthForm, AuthTextField } from "../../components/auth/AuthForm";
import { AuthPage } from "../../components/auth/AuthPage";
import { useNotifications } from "../../hooks/useNotifications";

const Form = ({ onSubmit, email }) => (
  <AuthForm
    onSubmit={onSubmit}
    links={[
      { path: "/auth/sign-in", label: "Sign In" },
      { path: "/auth/reset-password", label: "Reset Password" },
      { path: "/auth/sign-up", label: "Create Account" },
    ]}
  >
    <AuthTextField
      id="email"
      label="Email"
      type="email"
      autoComplete="email"
      defaultValue={email}
    />
    <AuthTextField id="code" label="Code" type="text" autoComplete="off" />
    <AuthButton label="Confirm Account" />
  </AuthForm>
);

export const AuthConfirmAccountPage = () => {
  const { confirmRegistration } = useUser();
  const [progress, setProgress] = useState(false);
  const { setNotifications, error, success } = useNotifications();
  const { push } = useHistory();

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setProgress(true);
      const formData = new FormData(event.currentTarget);
      try {
        await confirmRegistration(formData.get("email"), formData.get("code"));
        setNotifications([success("Your account has been successfully confirmed.")]);
        push("/auth/sign-in");
      } catch (err) {
        setNotifications([error(err.message)]);
      } finally {
        setProgress(false);
      }
    },
    [setProgress, confirmRegistration, push, setNotifications, error, success, setProgress]
  );

  return <AuthPage title="Confirm Account" progress={progress} Form={Form} onSubmit={onSubmit} />;
};
